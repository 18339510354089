<template>
  <v-card
    title="card-title"
    elevation="1"
    class="none-overflow"
    v-ripple
    @click="goAnalyse"
    ><v-card-title class="card-title">最近7日</v-card-title>

    <v-card-subtitle>最近7日学习情况</v-card-subtitle>
    <div class="stastic-content">
      <v-sparkline
        :fill="fill"
        :gradient="selectedGradient"
        :line-width="width"
        :padding="padding"
        :smooth="radius || false"
        :value="value"
        auto-draw
      ></v-sparkline>
    </div>
    <v-card-actions>
      <v-btn rounded small plain class="right-button" @click="zzzMid"
        >查看统计
        <v-icon right> mdi-play </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "homeStasticCard",
  mixins: [buttonVibrate],
  data: () => ({
    fill: false,
    selectedGradient: ["#ffe3c9", "#ffaf64", "#ffc74b"],
    padding: 8,
    radius: 4,
    value: [0, 2, 5, 9, 5, 10, 3],
    width: 4,
  }),
  methods: {
    goAnalyse() {
      this.$router.push("/analyse");
    },
  },
};
</script>

<style lang="scss" scoped>
.card-title {
  font-weight: 700;
  font-size: 1.1rem;
  letter-spacing: 0.15rem;
  color: #404040;
  margin-top: -0.5rem;
}
.right-button {
  position: absolute;
  right: 0.6rem;
  top: 0.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  color: #dc7841;
  font-size: 0.9rem;
  font-weight: 700;
  width: 6.5rem;
  ::v-deep .v-btn__content {
    opacity: 1 !important;
  }
}
</style>
